/* guide to breakpoints

    <------XS------|a|------SM------|b|------MD------|c|------LG------|d|------XL------|e|----XXL----->
    <----LT-SM-----|a|
    <----LT-MD----------------------|b|
    <----LT-LG---------------------------------------|c|
    <----LT-XL--------------------------------------------------------|d|
    <----LT-XXL------------------------------------------------------------------------|e|
                   |a|------GT-XS--------------------------------------------------------------------->
                                    |b|------GT-SM---------------------------------------------------->
                                                     |c|------GT-MD----------------------------------->
                                                                      |d|------GT-LG------------------>
                                                                                       |e|---GT-XL---->
*/
.intro-article {
  text-align: center;
}
@media only screen and (max-width: 539px) {
  .intro-article {
    padding-bottom: 0;
  }
  .intro-article .intro-cta {
    position: absolute;
    bottom: 0;
  }
  .intro-article .subtitle {
    width: 80%;
    margin: 0 auto 15px;
  }
}
.intro-article .title,
.intro-article .subtitle {
  line-height: 1;
  text-transform: uppercase;
}
.intro-article .title {
  font-size: 40px;
}
@media only screen and (max-width: 1024px) {
  .intro-article .title {
    font-size: 25px;
  }
}
@media only screen and (max-width: 539px) {
  .intro-article .title {
    float: none;
    width: 100%;
    margin-bottom: 15px;
    font-size: 24px;
  }
}
.intro-article .subtitle {
  font-size: 19px;
  line-height: 1.1;
  margin-bottom: 30px;
}
@media only screen and (max-width: 1024px) {
  .intro-article .subtitle {
    font-size: 13px;
  }
}
@media only screen and (max-width: 539px) {
  .intro-article .subtitle {
    font-size: 14px;
  }
}

.articles {
  background: linear-gradient(to bottom, #ebebeb, rgba(255, 255, 255, 0) 60px);
  background: -webkit-linear-gradient(top, #ebebeb, rgba(255, 255, 255, 0) 60px);
  overflow: auto;
  padding: 70px 0;
}
@media only screen and (max-width: 539px) {
  .articles {
    padding: 30px 0;
    background: linear-gradient(to bottom, #ebebeb, #fff 30px);
    background: -webkit-linear-gradient(top, #ebebeb, #fff 30px);
  }
}
.articles a {
  display: block;
}
.articles .container::after {
  clear: both;
  content: "";
  display: table;
}
.articles .articles-wrap {
  overflow: auto;
}
@media only screen and (max-width: 539px) {
  .articles .articles-wrap {
    margin-bottom: 60px;
  }
}
.articles .col {
  width: 25%;
  border-right: solid 1px #dcdcdb;
}
.articles .col:last-child {
  border-right: none;
}
@media only screen and (max-width: 539px) {
  .articles .col {
    width: 50%;
    border-right: none;
  }
  .articles .col:first-child {
    width: 100%;
    border-right: none;
  }
  .articles .col:last-child {
    display: none;
  }
}
.articles .teaser {
  overflow: hidden;
  width: 86.9565217391%;
}
.articles .image {
  position: relative;
  overflow: hidden;
}
.articles .video-icon {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}
.articles .video-icon > div {
  width: 20%;
  margin: 15% auto 0;
}
@media only screen and (max-width: 539px) {
  .articles .intro-cta {
    width: 100%;
  }
  .articles .intro-cta a {
    width: 94% !important;
    display: inline-block;
    width: auto;
    height: auto;
    padding: 12px 15px;
    background-color: #919396;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 1.1;
    text-align: center;
    color: #fff;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;
  }
  .articles .intro-cta a:hover, .articles .intro-cta a:active {
    background-color: #77797d;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
}
.articles .mobile-cta {
  display: none;
  margin: 15px 0;
}
@media only screen and (max-width: 539px) {
  .articles .mobile-cta {
    display: block;
  }
}
.articles .mobile-cta .btn {
  width: 100%;
}
.articles.reskin {
  background: none;
  padding: 40px 0;
}
.articles.reskin h2,
.articles.reskin h3 {
  margin: 5px;
  text-align: center;
}
.articles.reskin h3.subtitle {
  font-size: 20px;
  padding: 0 15%;
}
@media only screen and (max-width: 539px) {
  .articles.reskin h2 {
    font-size: 28px;
    font-size: 7vw;
  }
}
.articles.reskin .col {
  width: 33%;
  border-right: none;
}
@media only screen and (max-width: 539px) {
  .articles.reskin .col:last-child {
    display: block;
  }
}
.articles.reskin .articles-wrap {
  margin: 30px 0;
  overflow: initial;
}
@media only screen and (max-width: 1024px) {
  .articles.reskin .articles-wrap {
    margin: 30px 0 60px;
  }
}
@media only screen and (max-width: 539px) {
  .articles.reskin .articles-wrap {
    margin: 20px 0 60px;
  }
}
.articles.reskin .teaser .image {
  height: auto;
  max-height: none;
}
.articles.reskin .teaser .image img {
  height: auto;
  max-width: none;
  width: 100%;
}
@media only screen and (max-width: 539px) {
  .articles.reskin .teaser .image {
    height: auto;
    max-height: 55vw;
  }
}
.articles.reskin .teaser .title {
  font-size: 18px;
}
@media only screen and (max-width: 539px) {
  .articles.reskin .teaser .title {
    font-size: 14px;
    text-align: center;
    width: 100%;
  }
}
.articles.reskin .intro-cta {
  text-align: center;
}
.articles.reskin .intro-cta a,
.articles.reskin .intro-cta a:hover {
  display: inline-block;
  color: #fff;
  background-color: #d81e05;
}

.articles-carousel {
  background: linear-gradient(to bottom, #ebebeb, rgba(255, 255, 255, 0) 60px);
  background: -webkit-linear-gradient(top, #ebebeb, rgba(255, 255, 255, 0) 60px);
  padding: 40px 0;
}
@media only screen and (max-width: 767px) {
  .articles-carousel {
    padding: 40px 40px 0 40px;
  }
}
.articles-carousel .lead-img {
  margin: 10px 0 30px;
  text-align: center;
}
.articles-carousel .img-container {
  position: relative;
  overflow: hidden;
  max-height: 150px;
}
.articles-carousel .img-container img {
  width: 100%;
}
.articles-carousel .video-icon {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}
.articles-carousel .video-icon > div {
  width: 20%;
  margin: 15% auto 0;
}
.articles-carousel .title {
  margin: 0.5em 0 0.2em;
  line-height: 1.2em;
  text-transform: uppercase;
}
@media only screen and (min-width: 768px) {
  .articles-carousel .title {
    text-align: center;
  }
  .articles-carousel .title.align-left {
    width: 90%;
    margin: 0 auto;
    text-align: left;
  }
}
@media only screen and (min-width: 1025px) {
  .articles-carousel .title.align-left {
    padding-left: 2.6666666667%;
  }
  .articles-carousel .title.align-left[data-slides-desktop="4"] {
    padding-left: 2%;
  }
}
@media only screen and (max-width: 1024px) {
  .articles-carousel .title.align-left {
    padding-left: 4%;
  }
  .articles-carousel .title.align-left[data-slides-tablet="3"] {
    padding-left: 2.6666666667%;
  }
}
.articles-carousel > p {
  width: 60%;
  margin: 1em 20%;
}
.articles-carousel .carousel {
  width: 96%;
  margin: 40px auto 0;
}
.articles-carousel .slide-content {
  padding: 0 8%;
}
.articles-carousel .slide-title {
  margin: 0.5em 0;
}
.articles-carousel .slide-title a {
  overflow: hidden;
  /** limit to 2 lines so it doesn't break the slide layouts
  /** widely supported with the prefix 97.66%
  /** https://caniuse.com/?search=line-clamp */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  /** 96.65%
  /** https://caniuse.com/?search=box-orient */
  -webkit-box-orient: vertical;
}
.articles-carousel .slide-title a:hover {
  text-decoration: none !important;
  color: #333 !important;
}
@media only screen and (max-width: 767px) {
  .articles-carousel .lead-img {
    margin-bottom: 20px;
  }
  .articles-carousel > p {
    width: 100%;
    margin: 1em 0;
  }
  .articles-carousel .carousel {
    width: 100%;
    margin-top: 20px;
  }
  .articles-carousel .slide-content {
    padding: 0;
  }
  .articles-carousel .title {
    line-height: 1em;
    text-align: center;
  }
  .articles-carousel .slide-title {
    margin: 0.5em 0;
  }
  .articles-carousel .slide-title a {
    text-decoration: none;
    color: #333;
  }
}
.articles-carousel .slide-content p {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  overflow: hidden;
  -webkit-box-orient: vertical;
}
.articles-carousel .swiper .swiper-button-next {
  right: 0;
}
.articles-carousel .swiper .swiper-button-prev {
  left: 0;
}
.articles-carousel .swiper .swiper-button-prev:after,
.articles-carousel .swiper .swiper-button-next:after {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-size: 36px;
  font-weight: normal;
  color: #000;
}
.articles-carousel .swiper .swiper-button-prev:after {
  content: "<";
}
.articles-carousel .swiper .swiper-button-next:after {
  content: ">";
}
@media only screen and (max-width: 767px) {
  .articles-carousel .swiper-wrapper {
    padding-bottom: 30px;
  }
}
.articles-carousel .swiper-pagination {
  position: relative;
  margin-top: 20px;
}
/* guide to breakpoints

    <------XS------|a|------SM------|b|------MD------|c|------LG------|d|------XL------|e|----XXL----->
    <----LT-SM-----|a|
    <----LT-MD----------------------|b|
    <----LT-LG---------------------------------------|c|
    <----LT-XL--------------------------------------------------------|d|
    <----LT-XXL------------------------------------------------------------------------|e|
                   |a|------GT-XS--------------------------------------------------------------------->
                                    |b|------GT-SM---------------------------------------------------->
                                                     |c|------GT-MD----------------------------------->
                                                                      |d|------GT-LG------------------>
                                                                                       |e|---GT-XL---->
*/
.vehicle-info-bar {
  position: relative;
  min-height: 50px;
  height: auto;
  font-size: 20px;
  width: 100%;
  color: #fff;
  line-height: 50px;
}
@media only screen and (max-width: 767px) {
  .vehicle-info-bar {
    min-height: auto;
    line-height: 1em;
    font-size: 15px;
    text-align: center;
  }
}
.vehicle-info-bar > .container {
  padding: 0;
}
@media only screen and (max-width: 767px) {
  .vehicle-info-bar > .container {
    padding: 8px 0;
  }
}
.vehicle-info-bar .container {
  position: relative;
  background: #005eb8;
}
@media only screen and (max-width: 767px) {
  .vehicle-info-bar .container {
    margin: 0;
    text-align: center;
  }
}
.vehicle-info-bar .vehicle,
.vehicle-info-bar dt,
.vehicle-info-bar dd {
  display: inline-block;
  padding: 0;
  margin: 0;
}
.vehicle-info-bar dt {
  padding: 0 5px 0 12px;
}
@media only screen and (max-width: 767px) {
  .vehicle-info-bar dt {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .vehicle-info-bar dd {
    font-size: 15px;
    line-height: 1em;
    padding: 0 8px 5px;
  }
}
@media only screen and (max-width: 767px) {
  .vehicle-info-bar .vehicle {
    padding-top: 0;
  }
}
.vehicle-info-bar .change-vehicle {
  font-size: 16px;
  padding-right: 12px;
  float: right;
  color: #fff;
  text-decoration: underline;
}
.vehicle-info-bar .change-vehicle:hover {
  color: #ededed;
}
@media only screen and (max-width: 767px) {
  .vehicle-info-bar .change-vehicle {
    padding-right: 0;
    font-size: 15px;
    float: none;
  }
}
.vehicle-info-bar .horizontal-vehicle-filter {
  background: transparent;
  font-size: 14px;
  line-height: 1.45em;
  padding: 0;
}
.vehicle-info-bar .horizontal-vehicle-filter .container {
  padding: 0;
  margin: 0;
}
.vehicle-info-bar .horizontal-vehicle-filter .filters-wrap {
  background: transparent;
}
.vehicle-info-bar .horizontal-vehicle-filter .filters-wrap-inner {
  width: 100%;
  padding: 15px 10px 0;
}
.vehicle-info-bar .horizontal-vehicle-filter fieldset a.tooltop {
  color: #fff;
}
.vehicle-info-bar.grid {
  color: black;
  padding: 0;
  overflow: visible;
}
.vehicle-info-bar.grid dt {
  font-size: 26px;
  padding: 0;
  text-transform: uppercase;
}
@media screen and (max-width: 768px) {
  .vehicle-info-bar.grid dt {
    display: inline-block;
  }
}
.vehicle-info-bar.grid .container {
  background: transparent;
  border-bottom: 1px solid #ededed;
}
.vehicle-info-bar.grid .container .coral-Popover-content .filters-wrap {
  margin: 0;
}
.vehicle-info-bar.grid .container .coral-Popover-content .container .filters-wrap {
  background: #f6f6f6;
}
.vehicle-info-bar.grid .container .vehicle {
  width: 49%;
  display: inline-block;
  line-height: normal;
}
@media only screen and (max-width: 767px) {
  .vehicle-info-bar.grid .container .vehicle {
    width: 100%;
    padding: 0 5px;
  }
}
.vehicle-info-bar.grid .container .triple-guarantee-desktop {
  position: relative;
  display: inline-block;
  width: 50%;
  height: auto;
  text-align: right;
  vertical-align: top;
  top: -13px;
}
@media screen and (max-width: 824px) {
  .vehicle-info-bar.grid .container .triple-guarantee-desktop {
    top: -10px;
  }
  .vehicle-info-bar.grid .container .triple-guarantee-desktop img {
    width: 90%;
  }
}
@media screen and (max-width: 768px) {
  .vehicle-info-bar.grid .container .triple-guarantee-desktop {
    display: none;
  }
}
.vehicle-info-bar.grid .change-vehicle {
  color: black;
  float: none;
  font-size: 24px;
  padding: 3px 0 0 11px;
}
.vehicle-info-bar.popOver .coral-Popover {
  display: block;
}

.vehicle-info-bar--v2 .vehicle-lookup {
  margin: 20px auto;
}
.vehicle-info-bar--v2.grid .vehicle-name {
  color: #d81e05;
  font-size: 25px;
}
@media only screen and (max-width: 767px) {
  .vehicle-info-bar--v2.grid span {
    padding: 0;
  }
}
.vehicle-info-bar--v2.grid .container {
  border-bottom: none;
}
.vehicle-info-bar--v2.grid .container .vehicle {
  width: 100%;
  display: flex;
}
@media only screen and (max-width: 767px) {
  .vehicle-info-bar--v2.grid .container {
    margin: 0 4.8611111111%;
    text-align: left;
  }
}
.vehicle-info-bar--v2 a:hover {
  text-decoration: none !important;
}
.vehicle-info-bar--v2 .icon-edit {
  position: relative;
  top: -3px;
  display: inline-block;
  width: 17px;
  height: 17px;
  mask: url(https://s7d1.scene7.com/is/content/bridgestone/edit-2021-icon-global-web-bsro);
  background-color: #d81e05;
  mask-size: cover;
}
.vehicle-info-bar--v2 .icon-edit:hover, .vehicle-info-bar--v2 .icon-edit:focus {
  background-color: #a61704;
}
.vehicle-info-bar--v2 .vehicle-info-bar__tsr {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.vehicle-info-bar--v2 .vehicle-info-bar__tsr h1 {
  margin: 0px;
  line-height: 0;
}
.vehicle-info-bar--v2 .results-count {
  font-family: "AvenirHeavy", Arial, sans-serif !important;
  font-size: 16px;
  line-height: 1.2em;
  text-transform: uppercase;
}
.vehicle-info-bar--v2 .results-count span {
  font-family: "Avenir", Arial, sans-serif !important;
  text-transform: lowercase;
}
.vehicle-info-bar--v2 .triple-guarantee {
  display: flex;
  width: 350px;
  align-items: center;
  background-color: #d81e05;
  border-radius: 5px;
  padding: 10px;
}
@media only screen and (max-width: 1024px) {
  .vehicle-info-bar--v2 .triple-guarantee {
    display: none;
  }
}
.vehicle-info-bar--v2 .triple-guarantee__logo {
  min-width: 30px;
  font-size: 12px;
  line-height: 1.2em;
}
.vehicle-info-bar--v2 .triple-guarantee__logo img {
  width: 30px;
  filter: invert(1);
}
.vehicle-info-bar--v2 .triple-guarantee__message {
  font-family: "Avenir", Arial, sans-serif !important;
  padding-left: 10px;
  font-size: 14px;
  color: #fff;
  line-height: 1.2em;
}
.vehicle-info-bar--v2 .triple-guarantee__message > div:first-child {
  font-family: "AvenirHeavy", Arial, sans-serif !important;
  margin-bottom: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid #fff;
  text-transform: uppercase;
}
.vehicle-info-bar--v2 .cfna-finance {
  display: flex;
  width: 350px;
  align-items: center;
  background-color: #d81e05;
  border-radius: 5px;
  padding: 10px;
  margin-top: 6px;
}
.vehicle-info-bar--v2 .cfna-finance:hover {
  text-decoration: none;
}
@media only screen and (max-width: 1024px) {
  .vehicle-info-bar--v2 .cfna-finance {
    display: none;
  }
}
.vehicle-info-bar--v2 .cfna-finance__logo {
  min-width: 30px;
  font-size: 12px;
  line-height: 1.2em;
}
.vehicle-info-bar--v2 .cfna-finance__logo img {
  width: 30px;
  filter: invert(1);
}
.vehicle-info-bar--v2 .cfna-finance__message {
  font-family: "Avenir", Arial, sans-serif !important;
  padding-left: 10px;
  font-size: 13px;
  color: #fff;
  line-height: 1.2em;
}